<template>
  <div>
    <a-form
      class="custom-compact-form"
      :form="form"
      style="width: 1200px;"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 8 }"
      @submit="handleSubmit"
    >

      <a-form-item label="版本名称">
        <a-input
          v-decorator="['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入版本名称' },
              { max: 20, message: '最多20个字符' },
            ]
          }]"
          autocomplete="off"
        />
      </a-form-item>

      <a-form-item label="版本号">
        <a-input-number
          :min="1"
          :max="10000000"
          :step="1"
          :precision="0"
          style="width: 100%"
          v-decorator="['version', {
            rules: [
              { required: true, message: '请输入版本号' },
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="文件" required>
        <upload-custom-file
          accept="application/vnd.android.package-archive"
          hint="支持apk格式文件，100MB以内"
          :max-size="100*1024*1024"
          :default-file-list="defaultFileList"
          @change="handleUploadChange"
          storageMethod="local"
          v-decorator="['attachment', {
            rules: [{ validator: checkFileList }],
          }]"
        />
      </a-form-item>

      <a-form-item label="更新信息">
        <a-textarea
          v-decorator="['content', {
            rules: [
              { max: 2000, message: '最多2000个字符' },
            ]
          }]"
        />
      </a-form-item>
      <a-form-item class="set-button-center">
        <a-button type="primary" html-type="submit" :loading="submitting">
          保存
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { findAppUpgrade, updateAppUpgrade } from '@/api/agreement'
import UploadCustomFile from '@/components/Upload/CustomFile'

export default {
  name: 'IndexAppUseAgreement',
  components: {
    UploadCustomFile
  },
  data() {
    return {
      fileList: [],
      defaultFileList: [],
      form: this.$form.createForm(this, { name: 'app_use_agreement_edit' }),
      submitting: false
    }
  },
  created() {
    this.initEditor()
  },
  methods: {
    initEditor() {
      // 访问编辑框的时候加载以前保存的内容
      findAppUpgrade().then((res) => {
        this.defaultFileList = []
        if (res.data.attachment) {
          const file = res.data.attachment
          this.defaultFileList.push({
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: file.url,
            response: file.response
          })
        }
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: res.data.name,
            version: res.data.version > 0 ? res.data.version : undefined,
            content: res.data.content
          })
          this.fileList = this.defaultFileList
        })
      })
    },

    handleUploadChange(fileList) {
      this.fileList = fileList
    },

    checkFileList(rule, value, callback) {
      if (!this.fileList[0] || this.fileList[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传文件')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.fileList, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachment: this.fileList[0].response
          }
          updateAppUpgrade(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.set-button-center {
  text-align: center;
}
</style>
